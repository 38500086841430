import React from "react"

import InlineLink from "../Button/InlineLink"

import styles from "./Intro.module.scss"

const Intro = () => (
  <div className={styles.intro}>
    <p className={styles.greeting}>hello!</p>
    <p className={styles.large}>
      I'm a Colorado based{" "}
      <InlineLink onClick={() => null}>software developer</InlineLink> and{" "}
      <InlineLink onClick={() => null}>designer</InlineLink> with over a decade
      of experience helping to plan, design, build, and launch great digital
      experiences.
    </p>
    <p>
      Interested in learning more about my experience? Check out my{" "}
      <a
        href={"/charlie-stanard_resume_software-developer.pdf"}
        target="_blank"
        rel="noopener noreferrer"
      >
        resume
      </a>
      . Think we might work well together?{" "}
      <a href="mailto:louis.stanard@gmail.com">Get&nbsp;in&nbsp;touch</a>
      &nbsp;
      <span role="img" aria-label="live long and prosper">
        🖖
      </span>
    </p>
  </div>
)

export default Intro
