import React from "react"
import PropTypes from "prop-types"

import styles from "./InlineLink.module.scss"

/**
 * For this initial launch I'm not styling this to look like a button,
 * just want to have the component ready.
 */
const InlineLink = ({ children, onClick }) => {
  return (
    <button type="button" className={styles.link} onClick={onClick}>
      {children}
    </button>
  )
}

InlineLink.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default InlineLink
